import "./App.css";
import { Main } from "./pages/main/main.index";

import { Routes, Route, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Sidebar } from "./components/sidebar/sidebar";
import { Filter } from "./pages/filter/filter.index";
import { Header } from "./components/header/header";
import { Step2 } from "./pages/step2/step2.index";
import { searchClient } from "./typesenseAdapter";
import { InstantSearch } from "react-instantsearch-dom";
import { Login } from "./pages/login/login";
import { AppContext } from "./context/AppContext";
import { firebase } from "./firebase/config";
import { addProjectToDB, getAllProjects } from "./firebase/firestoreUtils";
import { PatientDetails } from "./pages/patient-details";
import { PatientEvaluation } from "./pages/patient-evaluation";
import { CoverageValidation } from "./pages/coverage-validation";
import EvaluationList from "./pages/evaluation-list";

export const routePaths = {
  root: "/",
  chatWithDoc: "/chat-with-doc",
  chatWithAI: "/chat-with-ai",
  login: "/login",
  patientDetails: "/patient-details",
  patientEvaluation: "/patient-evaluation",
  evaluationList: "/evaluation-list",
  coverageValidation: "/coverage-validation",
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={routePaths.root} element={<Filter />} />
      <Route exact path={routePaths.login} element={<Login />} />
      <Route exact path={routePaths.chatWithDoc} element={<Step2 />} />
      <Route exact path={routePaths.chatWithAI} element={<Main />} />
      <Route
        exact
        path={routePaths.evaluationList}
        element={<EvaluationList />}
      />
      <Route
        exact
        path={routePaths.patientDetails}
        element={<PatientDetails />}
      />
      <Route
        exact
        path={routePaths.patientEvaluation}
        element={<PatientEvaluation />}
      />
      <Route
        exact
        path={routePaths.coverageValidation}
        element={<CoverageValidation />}
      />
    </Routes>
  );
};

function App() {
  const [expandSidebar, setExpandSidebar] = useState(false);
  const { appState, setAppState } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setAppState((prev) => ({ ...prev, loading: true }));
    const unsub = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const uid = user?._delegate?.uid;
        const tokenKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
        const token = await user.getIdToken(true);

        // const encryptedToken = encryptToken(token);
        // console.log(tokenKey, "tokenKey", token, encryptedToken);
        // localStorage.setItem(tokenKey, encryptedToken);
        // setUser(user?._delegate);
        setAppState((prev) => ({ ...prev, userData: user?._delegate }));
      }
      setAppState((prev) => ({ ...prev, loading: false }));
    });

    return unsub;
    // eslint-disable-next-line
  }, []);

  return (
    <InstantSearch
      indexName="medicalData-embedded-chunked"
      searchClient={searchClient}
    >
      <div className="d-flex h-100 flex-1 app-div">
        <Sidebar
          expandSidebar={expandSidebar}
          setExpandSidebar={setExpandSidebar}
          handleNewChat={() => {}}
        />
        <div className="w-100">
          <Header />

          <AppRoutes />
        </div>
      </div>
    </InstantSearch>
  );
}

export default App;
