import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

let TYPESENSE_SERVER_CONFIG = {
  apiKey: "uKey5bP7PWQphxobLkwtTjtzQrlgpnje", // Be sure to use an API key that only allows searches, in production
  nodes: [
    {
      host: "dkmzb73oyjeg890rp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 1,
  numRetries: 8,
};

export const typesenseAdapter = new TypesenseInstantsearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    // queryBy: "title,source,indication,effective_date,type,last_review_date,url,background",
    queryBy: "title",
    // queryByWeights: "1,3,2,4,5,6,7,8",
    queryByWeights: "1",
    numTypos: 8,
    typoTokensThreshold: 1,
    per_page: 20,
  },
});

export const searchClient = typesenseAdapter.searchClient;
