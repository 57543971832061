import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { fetchGet } from "../utils/fetchGet";
import { BASE_URL } from "../constants/appConstants";

const EvaluationList = () => {
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false);

  const init = async () => {
    try {
      setLoading(true);
      const res = await fetchGet(`${BASE_URL}/requests/all`);
      console.log(res?.data?.data);
      setRequests(res?.data?.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <div className="container position-relative mt-4 px-4 animate-left-to-right">
      <p className="ff-os fc-cprimary m-0 fs-20 fw-600 mb-3">Evaluation list</p>
      {loading ? (
        <div className="w-100 h-100 d-flex align-items-center mt-3">
          <div class="spinner-grow bg-cprimary" role="status" />
        </div>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th className="fs-14 fw-600">Request id</th>
              <th className="fs-14 fw-600">Insurance Company</th>
              <th className="fs-14 fw-600">Policies</th>
              <th className="fs-14 fw-600">Create date</th>
              <th className="fs-14 fw-600">Status</th>
            </tr>
          </thead>
          <tbody>
            {requests?.map((item, index) => (
              <tr key={index}>
                <td className="fs-14 fw-500">{item._id}</td>
                <td className="fs-14 fw-500">{item.insuranceCompany}</td>
                <td className="fs-14 fw-500">
                  {item?.policies?.map((pol, index) => (
                    <p className="m-0 fs-14 fw-500">
                      {index + 1}. {pol?.label}
                    </p>
                  ))}
                </td>
                <td className="fs-14 fw-500">{item.createdAt}</td>
                <td className="fs-14 fw-500">{item.requestStatus}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default EvaluationList;
