import { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { auth, firebase } from "../../firebase/config";
const provider = new firebase.auth.GoogleAuthProvider();

const USER_MEGS = {
  NUMBER_NOT_FORMATTED: {
    success: false,
    msg: "Please enter a valid number.",
  },
  WRONG_EMAIL_PASSWORD: {
    success: false,
    msg: "Wrong email or password",
  },
  OTP_SENT_SUCCESS: {
    success: true,
    msg: "Otp has been sent please check your mobile.",
  },
  OTP_SENT_FAILURE: {
    success: false,
    msg: "Please try again.",
  },
  OTP_VERIFY_SUCCESS: {
    success: true,
    msg: "Otp verified successfully.",
  },
  OTP_VERIFY_FAILURE: {
    success: false,
    msg: "Incorrect otp.",
  },
  NOT_AUTHORIZED: {
    success: false,
    msg: "You aren't authorized to login",
  },
  OTP_LENGTH: {
    success: false,
    msg: "Please enter 6 digits.",
  },
  EMAIL_NOT_VERIFIED: {
    success: false,
    msg: "Please click on the link that has been sent to your email account to verify your email.",
  },
};

const Login = () => {
  const [showOtp] = useState(false);
  const [userMsg, setUserMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);

  const { appState, setAppState } = useContext(AppContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserMsg(null);
    var formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    setLoading(true);

    try {
      const firebaseAuthRes = await auth?.signInWithEmailAndPassword(
        email,
        password
      );

      const token = await firebaseAuthRes?.user.getIdToken(true);

      console.log(firebaseAuthRes, firebaseAuthRes?.user?.email, token);

      //   if (
      //     !allowedUnverifiedAccounts?.includes(firebaseAuthRes?.user?.email) &&
      //     !firebaseAuthRes?.user?.emailVerified
      //   ) {
      //     return setUserMsg(USER_MEGS.EMAIL_NOT_VERIFIED);
      //   }
      console.log(firebaseAuthRes?.user?._delegate?.uid, "firebaseAuthRes?.user")
      setAppState((prev) => ({ ...prev, userData: firebaseAuthRes?.user?._delegate }));

      navigate("/");
      //   setUser(firebaseAuthRes?.user);
      //   updateContext(setValueByKey, userDetails);
      //   setValueByKey("token", token);
    } catch (err) {
      console.log(err?.code, err);
      if (err?.code === "auth/wrong-password") {
        setUserMsg(USER_MEGS.WRONG_EMAIL_PASSWORD);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const signIn = () => {
    setGoogleLoginLoading(true);
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (data) => {
        const userData = data?.user?._delegate;
        setAppState((prev) => ({ ...prev, userData }));

        navigate("/");
        setGoogleLoginLoading(true);
      })
      .catch((error) => alert(error.message));
  };
  return (
    <div className="">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-7 col-md-3 mx-auto my-4">
          <h4 className="text-center mb-3 ff-os fw-600 hello-greeting fs-20 mb-5">Login to {appState?.appName}</h4>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label ff-os fw-600 fc-csecondary">Email</label>
              <input
                disabled={googleLoginLoading || loading}
                required
                type="email"
                name="email"
                className="form-control shadow-none border-cprimary"
                readOnly={showOtp}
              />
            </div>
            <div className={`mb-${showOtp ? "4" : "1"}`}>
              <label className="form-label ff-os fw-600 fc-csecondary">Password</label>
              <input
                disabled={googleLoginLoading || loading}
                required
                type="password"
                name="password"
                className="form-control shadow-none border-cprimary"
                readOnly={showOtp}
              />
            </div>

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 ff-os text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>

            <button
              disabled={googleLoginLoading || loading}
              type="submit"
              className="btn w-100 btn-outline-primary fc-cprimary border-cprimary btn-cprimary shadow-none ff-os fw-500 text-uppercase"
            >
              {loading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm ff-os me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only ff-os">Loading...</span>
                </>
              ) : (
                "Login"
              )}
            </button>
          </form>
          <div className="d-flex mt-3 justify-content-between">
            <Link
              className="fw-500 ff-os fs-14 fc-cprimary"
              // to="/forgot-password"
            >
              Forgot Password?
            </Link>
            <div className="d-flex flex-column flex-md-row">
              <p className="ff-os m-0 me-2 ff-tw ff-os fs-14">Not a member?</p>
              <Link
                className="fw-500 ff-os fs-14 fc-cprimary"
                //   to="/register"
              >
                Register now
              </Link>
            </div>
          </div>

          {/* {!showOtp && (
            <div className="my-5 border-top py-5">
              <div className="h-100 d-flex align-items-center justify-content-center">
                <button
                  disabled={googleLoginLoading || loading}
                  className="w-100 btn btn-cprimary border-cprimary ff-os"
                  onClick={signIn}
                >
                  {googleLoginLoading ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only ff-os">Please wait...</span>
                    </>
                  ) : (
                    <div className="d-flex justify-content-around">
                      <i className="bi bi-google"></i>
                      <p className="ff-os m-0 w-75 ff-tw fs-16 fw-600 text-center ff-os">
                        SignIn with Google
                      </p>
                    </div>
                  )}
                </button>
              </div>

              <div className="h-100 d-flex align-items-center justify-content-center mt-4">
                <Link
                  disabled={googleLoginLoading || loading}
                   className="w-100 btn btn-cprimary border-cprimary d-flex justify-content-around"
                  // to="/mobile-login"
                >
                  <i class="bi bi-phone"></i>
                  <p className="ff-os m-0 w-75 ff-tw fs-16 fw-600 text-center ff-os">
                    Login using Mobile
                  </p>
                </Link>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export { Login };
