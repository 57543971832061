import React, { useContext } from "react";
import MovieHighlight from "../movieHighlight";
import { AppContext } from "../../context/AppContext";
import "./movieHit.css";

export function MovieHit(props) {
  const { hit } = props;
  const { appState, setAppState } = useContext(AppContext);

  return (
    <div className="w-100 my-3 p-3 rounded shadow-2 border-cprimary pointer">
      {/* <MoviePoster src={hit.image} alt={hit.title} /> */}
      <div className="text-left h4 font-weight-bold">
        {/* <MovieHighlight hit={hit} attribute="title" />{" "} */}
        <MovieHighlight
          hit={hit}
          attribute="title"
          className="fs-20 fw-600 fc-cprimary"
        />{" "}
      </div>
      <div className="text-left">
        {/* <MovieHighlight hit={hit} attribute="overview" />{" "} */}
        {/* <MovieHighlight isDate hit={hit} attribute="date" />{" "} */}
      </div>
      <div className="text-left">
        {/* <MovieHighlight hit={hit} attribute="overview" />{" "} */}
        <MovieHighlight
          hit={hit}
          attribute="lcd_id"
          className={"fw-500"}
        />{" "}
      </div>
      <div>
        {/* <MovieHighlight hit={hit} attribute="vote_average" />{" "} */}
        <span className="border px-2 fs-16 fw-400 source-box">{hit?.source}</span>
        <a
          href={hit?.url}
          target="_blank"
          className="border px-2 fs-16 fw-400 ms-2 source-box"
        >
          Source link
        </a>
      </div>
      <div className="h6 mt-2 font-weight-bold long-text">
        {/* <MovieHighlight hit={hit} attribute="vote_average" />{" "} */}
        <MovieHighlight hit={hit} attribute="indication" />{" "}
      </div>

      <div className="d-flex align-items-end justify-content-end mt-3 mb-2">
        <button
          onClick={() => {
            setAppState((prev) => ({
              ...prev,
              resDetails: hit,
            }));
            window.scrollTo(0, 0);
          }}
          className={`btn btn-cprimary fw-400 fs-14 ml-auto ${
            appState?.resDetails?.lcd_id === hit?.lcd_id ? "active" : ""
          }`}
        >
          More details
        </button>
      </div>
    </div>
  );
}
