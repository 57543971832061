import { createContext, useEffect } from "react";
import { useState } from "react";

export const AppContext = createContext();
export const SIDEBAR_EXPANDED_WIDTH = 225;

export const intialContextValues = {
  sidebarWidth: SIDEBAR_EXPANDED_WIDTH,
  selectedDocs: null,
  allDocsSelected: false,
  loading: true,
  userData: null,
  currentProject: null,
  showCreateProjectModal: false,
  headerLoading: "header",
  appName: "Generative Technologies Application",
  resDetails: null
};

const AppContextProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    ...intialContextValues,
  });

  useEffect(() => {
    // fetch the selected project id instead
    let selected_doc = localStorage.getItem("selected_doc");
    if (selected_doc) {
      selected_doc = JSON.parse(selected_doc);
    }
    let allDocsSelected = localStorage.getItem("allDocsSelected");
    if (allDocsSelected) {
      allDocsSelected = JSON.parse(allDocsSelected);
    }

    setAppState((prev) => ({
      ...prev,
      selectedDocs: selected_doc,
      allDocsSelected,
    }));
  }, []);

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContextProvider };
