import { useContext, useEffect, useRef, useState } from "react";
import "./step2.index.css";
import { Avatar } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import typesense from "../../typesenseAdapter";
import { routePaths } from "../../App";

const response = [
  `
  <p><span class="fc-cprimary">Stop and arrest:</span> If an officer suspects a driver is under the influence, they will pull the vehicle over and conduct a series of sobriety tests. If the driver fails these tests or refuses to take them, the officer will arrest the driver for DUI.</p>
  <p><span class="fc-cprimary">Investigation and evidence collection:</span> Once a driver is arrested for DUI, the officer will investigate the scene and collect evidence such as the driver’s blood alcohol content (BAC) level, the results of sobriety tests, and the driver’s behavior and appearance.<p>
  <p><span class="fc-cprimary">Testifying in court:</span> If the case goes to trial, the officer may be called as a witness to testify about the arrest and the evidence they collected.<p>
  <p><span class="fc-cprimary">Enforcing laws and regulations:</span> Law enforcement officers are responsible for enforcing all laws and regulations, including those related to drunk driving. They will also enforce laws related to open containers, and also any other vehicle code violation that is noticed while conducting a DUI investigation.<p>
  <p><span class="fc-cprimary">Providing public safety:</span> Law enforcement officers also play an important role in promoting public safety by enforcing laws against drunk driving, which can help reduce the number of DUI-related accidents and fatalities on the roads.<p>
`,
];

export const Step2 = () => {
  const [convo, setConvo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const containerRef = useRef(null);
  const { appState } = useContext(AppContext);
  const [selectedStep, setSelectedStep] = useState(0);
  const navigate = useNavigate();
  const [selectedDoc, setSelectedDoc] = useState(null);

  useEffect(() => {
    let index = 0;

    const intervalId = setInterval(() => {
      if (index < response.length) {
        setMessages((prevMessages) => [...prevMessages, response[index]]);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [response]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [convo]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e?.target);
    const prompt = formData.get("prompt");
    setConvo((prev) => [
      ...prev,
      {
        message: [prompt],
        isUser: true,
      },
    ]);

    setLoading(true);
    e?.target?.reset();

    setTimeout(() => {
      setLoading(false);
      setConvo((prev) => [
        ...prev,
        {
          message: response,
          isUser: false,
        },
      ]);
    }, 2000);
  };

  const handleNewChat = () => {
    setConvo([]);
  };

  useEffect(() => {
    let selected_doc = localStorage.getItem("selected_doc");
    if (selected_doc) {
      selected_doc = JSON.parse(selected_doc);
      setSelectedDoc(selected_doc);
    }
  }, []);

  return (
    <div className={`main-div animate-left-to-right`}>
      <div className="d-flex flex-column w-100 main-content">
        {/* main header */}

        <div
          ref={containerRef}
          className="d-flex flex-column align-items-start justify-content-start w-100 mx-auto main-div-chatbot"
          style={{
            height: "73vh",
          }}
        >
          <div className="flex-row w-100 d-flex align-items-center justify-content-between pointer user-select-none mb-2">
            <div>
              <i class="bi bi-chat fs-20 fw-600 hello-greeting me-3"></i>
              <p className="ff-os m-0 fs-16 text-center hello-greeting fw-500">
                Interact with{" "}
                <span className="fw-700 fc-cprimary">{selectedDoc?.title}</span>
              </p>
            </div>
            <button
              onClick={() => {
                navigate(routePaths.chatWithAI);
              }}
              type="button"
              className="btn text-secondary align-items-center d-flex"
            >
              <i class="bi bi-arrow-left fs-20"></i>
              <span className="ms-2">Previous</span>
            </button>
          </div>
          <div class="w-100 alert alert-light mt-2" role="alert">
            <p className="ff-os m-0 fs-14 fw-400 my-2">
              Engage in Conversational Interactions with {selectedDoc?.title}.
            </p>
            <p className="ff-os m-0 fs-14 fw-400 my-2">
              {selectedDoc?.overview}
            </p>
          </div>

          <div className="container mt-3 p-0">
            {convo.map((con, idx) => (
              <div
                key={idx}
                className={`d-flex message ${con?.isUser ? "user" : "system"}`}
              >
                {con?.isUser ? (
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt="Akin"
                    className="fs-14 m-0"
                    src="/static/images/avatar/1.jpg"
                  />
                ) : (
                  <i class="bi bi-stars fs-24 fc-cprimary"></i>
                )}
                <div>
                  <p
                    className="m-0 ms-2 mt-1"
                    dangerouslySetInnerHTML={{ __html: con?.message }}
                  />
                  {/* <p >{con?.message}</p> */}
                  {!con?.isUser && (
                    <div className="ms-2 mt-4">
                      <i class="bi bi-hand-thumbs-up fs-18 fc-cprimary pointer"></i>
                      <i class="bi bi-hand-thumbs-down fs-18 ms-4 fc-cprimary pointer"></i>
                      <i class="bi bi-three-dots-vertical fs-18 ms-4 fc-cprimary pointer"></i>
                      <i class="bi bi-google fs-18 ms-4 fc-cprimary pointer"></i>
                      <i class="bi bi-clipboard fs-18 ms-4 fc-cprimary pointer"></i>
                      <i class="bi bi-share fs-18 ms-4 fc-cprimary pointer"></i>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {loading && (
              <div className="d-flex justify-content-start">
                <i class="bi bi-stars fs-24 fc-cprimary" style={{}}></i>
                <div className="w-100 ms-3">
                  <SkeletonTheme baseColor="#9b72cb50" highlightColor="#fff">
                    <Skeleton className="w-100 p-0 m-0" />
                    <Skeleton className="w-50" />
                    <Skeleton className="w-75" />
                  </SkeletonTheme>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            height: "17vh",
          }}
          className="d-flex flex-column align-items-center justify-content-center w-100"
        >
          <form onSubmit={handleFormSubmit} className="w-100 chatbot-form">
            <div className="input-div d-flex form-control">
              <input
                name="prompt"
                placeholder="Please enter a prompt here"
                className="w-100 bg-none outline-none shadow-none py-2 px-4"
              />
              <div className="d-flex align-items-center">
                <div className="mx-2">
                  <i class="bi bi-file-image fc-cprimary fs-24"></i>
                </div>
                <div className="mx-2">
                  <i class="bi bi-mic fs-24 fc-cprimary"></i>
                </div>
              </div>
            </div>
          </form>
          <p className="ff-os fs-12 m-0 mt-3">
            {appState?.appName} may display inaccurate info, including about
            people, so double-check its responses.
          </p>
        </div>
      </div>
    </div>
  );
};
