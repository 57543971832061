import React from "react";
import { connectHighlight } from "react-instantsearch-core";
let options = {
  year: "numeric",
  month: "long", // 'long' for full month name, 'short' for abbreviated month, 'numeric' for number
  day: "numeric",
};
const HitHighlight = ({ highlight, attribute, hit, className, isDate }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });

  // const x = parsedHit.map((part, index) =>
  //   part.isHighlighted ? (
  //     <b key={index}>
  //       <u>{part.value}</u>
  //     </b>
  //   ) : (
  //     <span key={index}>{part.value}</span>
  //   )
  // );

  const mapped = parsedHit?.reduce(
    (add, x, index) =>
      x.isHighlighted
        ? `<b key=${index}><u>${x.value}</u></b>`
        : `<span key=${index}>${x.value}</span>` + add,
    ""
  );
  // console.log(mapped, x, parsedHit);
  // return (
  //   <span className="d-inline-block hello-wrodl">
  //     {parsedHit.map((part, index) =>
  //       part.isHighlighted ? (
  //         // <b key={index}>
  //         //   <u>{part.value}</u>
  //         // </b>

  //         <span
  //           className="d-inline-block"
  //           dangerouslySetInnerHTML={{
  //             __html: `<b key=${index}>
  //            <u>${part.value}</u>
  //        </b>`,
  //           }}
  //           key={index}
  //         >
  //           {/* <span  /> */}
  //         </span>
  //       ) : (
  //         // <span key={index}>{part.value}</span>
  //         <span
  //           className=""
  //           dangerouslySetInnerHTML={{ __html: part?.value + "ss" }}
  //           key={index}
  //         >
  //           {/* <span  /> */}
  //         </span>
  //       )
  //     )}
  //     {/* inner - <div dangerouslySetInnerHTML={{ __html: mapped }} />
  //     {mapped + "mPPED"} */}
  //   </span>
  // );

  // return parsedHit.map((part, index) =>
  //   part.isHighlighted ? (
  //     <b key={index}>
  //       <u>{part.value}</u>
  //     </b>
  //   ) : (
  //     <span key={index}>{part.value}</span>
  //   )
  // );
  let date = null;
  if (isDate) {
    date = new Date(hit?.[attribute]);
  }

  return isDate && hit?.[attribute] ? (
    <p className="fw-700 d-inline-block py-1">
      {date.toLocaleDateString("en-US", options)}
    </p>
  ) : !["title"]?.includes(attribute) ? (
    <div className={`fs-16 fw-400 ${className}`}>
      <p dangerouslySetInnerHTML={{ __html: hit?.[attribute] }} />
    </div>
  ) : (
    parsedHit.map((part, index) =>
      part.isHighlighted ? (
        <b className="bg-cprimary" key={index}>
          <u>{part.value}</u>
        </b>
      ) : (
        <span key={index}>{part.value}</span>
      )
    )
  );
};

export default connectHighlight(HitHighlight);
