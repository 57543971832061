import { firestore } from "./config";

export const addProjectToDB = async (objToStore, uid) => {
  const docRef = firestore
    .collection("users")
    .doc(uid)
    .collection("projects")
    .doc();

  await docRef.set(objToStore);

  return docRef;
};

export const getAllProjects = async (uid) => {
  return await firestore
    .collection("users")
    .doc(uid)
    .collection("projects")
    .get();
};

export const updateProject = async (uid, projectDocId, updatedObj) => {
  const ref = firestore
    .collection("users")
    .doc(uid)
    .collection("projects")
    .doc(projectDocId);

  return await ref.update(updatedObj);
};
