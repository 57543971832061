// CustomRefinementList.js
import React, { useContext, useEffect } from "react";
import { connectRefinementList } from "react-instantsearch-dom";
import { useLocation } from "react-router-dom";
import { routePaths } from "../App";
import { AppContext } from "../context/AppContext";

const CustomRefinementList = ({ items, refine, setHasContent }) => {
  const location = useLocation();
  const { appState, setAppState } = useContext(AppContext);

  useEffect(() => {
    if (items?.length > 0) setHasContent(true);
  }, [items]);

  // const populateRefine = () => {
  //   // let refine_list_local = localStorage.getItem("refine_list");
  //   // if (refine_list_local) {
  //   //   refine_list_local = JSON.parse(refine_list_local);
  //   // }
  //   // const refine_list = refine_list_local;

  //   // console.log(refine_list, "refine_lissst");
  //   console.log(
  //     appState?.currentProject,
  //     "appState?.currentProject?.categories"
  //   );
  //   if (appState?.currentProject) {
  //     handleUrlParam(appState?.currentProject?.categories);
  //   }
  // };

  const handleUrlParam = (value) => {
    refine(value);
  };

  // useEffect(() => {
  //   if (location?.pathname === routePaths.root) {
  //     populateRefine();
  //   }
  // }, [location?.pathname, appState?.currentProject]);

  const handleItemClick = (item) => {
    handleUrlParam(item?.value);
    setAppState((prev) => ({
      ...prev,
      currentProject: {
        ...prev?.currentProject,
        categories: item?.value,
      },
    }));
    // localStorage.setItem("refine_list", JSON.stringify(item?.value));
  };

  return (
    <div className="bg-white p-0 m-0">
      {/* <p className="ff-os m-0 fs-18 mb-2 mt-3 fw-600 hello-greeting">Filter </p> */}

      {appState?.headerLoading === "header" ? (
        <div className="mt-4">
          <span
            class="spinner-border spinner-border-sm ff-os me-2"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only ff-os fs-14 fc-csecondary">
            Setting things up for you. Just a moment!
          </span>
        </div>
      ) : (
        <>
          {/* <div className="d-flex  align-items-center justify-content-between mb-3">
            <p className="ff-os m-0 fw-500">
              [{items?.reduce((a, item) => item?.count + a, 0)}]
            </p>
            <button
              onClick={() => {
                refine([]);
                localStorage.removeItem("refine_list");
              }}
              className="btn text-secondary shadow-none btn-clear border-none ff-os m-0 p-0 fs-14 fw-600"
            >
              Clear
            </button>
          </div> */}

          <div className="d-flex flex-wrap">
            {items?.map((item) => (
              <div
                key={item.label}
                className="col-6 mb-1 d-flex align-items-center"
              >
                <input
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={() => handleItemClick(item)}
                />
                <p className="ff-os fs-15 fw-400 m-0 mx-2">{item.label}</p>
                <p className="ff-os fw-500 fs-15 m-0">({item.count})</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

// Connect the custom component to the RefinementList logic
const ConnectedCustomRefinementList =
  connectRefinementList(CustomRefinementList);

export default ConnectedCustomRefinementList;
