import React, { useState, useEffect } from "react";
import axios from "axios";

//const address = "http://localhost:3000";
const address = "https://app.generative-technologies.com";

const Question = ({ question, onAnswerChange, answers }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
       {question.question} -- {question.type}
      </label>
      <select
        className="border rounded py-2 px-3 w-full mt-2"
        onChange={(e) => onAnswerChange(question.questionId, e.target.value)}
        value={answers[question.questionId]}
      >
        {question.answers.map((answer, idx) => (
          <option key={idx} value={answer}>
            {answer}
          </option>
        ))}
        <option value={-1}>
            Not filled
          </option>
      </select>
      <br/>
    </div>
  );
};

const ValidationNode = ({
    validationTree,
    onAnswerChange,
    answers
  }) => {
    return (
      <div className="ml-4">
        {/* Recursively render "or" or "and" nodes */}
        {validationTree.or ? (
          validationTree.or.map((node, idx) =>
              <ValidationNode
                key={idx}
                validationTree={node}
                onAnswerChange={onAnswerChange}
                answers={answers}
              />
            )
        ) : (
            validationTree.and ? (
                validationTree.and.map((node, idx) =>
                <ValidationNode
                    key={idx}
                    validationTree={node}
                    onAnswerChange={onAnswerChange}
                    answers={answers}
                />
            )) : (
              <Question
                question={validationTree}
                onAnswerChange={onAnswerChange}
                answers={answers}
              />
            )
          )
        }
      </div>
    );
  };

const Criteria = ({ criteria, onAnswerChange, status, answers }) => {
  return (
    <div className="p-4">
      <h6>
        {criteria.title}
        {status === "Covered" ? "✅" : "❌"}
      </h6>
      <p>{criteria.description}</p>

      <ValidationNode
        validationTree={criteria.validation_tree}
        onAnswerChange={onAnswerChange}
        answers={answers}
      />
    </div>
  );
};

const Policy = ({ policy, answers, setAnswers, statuses, setStatuses, authRequest }) => {
    const [policyStatus, setPolicyStatus] = useState(null);
    const [loading, setLoading] = useState(false);

  const onAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const autoFillAnswers = async () => {
    setLoading(true);
    try {
        const res = await axios.post(`${address}/check-coverage`,{
                authRequest: authRequest,
                policy: policy
            });

            console.log(res.data);
            for (let [key, value] of Object.entries(res.data)) {
                onAnswerChange(key, value)
            } 
            
        
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const calculateStatus = () => {
      let overallStatus = true;
      let updatedStatuses = policy.criteria.map((criteria, criteriaIndex) => {
        let isCovered = false;
        
        const validateTree = (currentNode) => {
            if (currentNode.or) {
              return currentNode.or.some((node) => {
                return validateTree(node);
              });
            } else if (currentNode.and) {
              return currentNode.and.every((node) => {
                return validateTree(node);
              });
            } else {
                return (
                    answers[currentNode.questionId] === currentNode.coverage_response
                );
            }
          };
  
          isCovered = validateTree(criteria.validation_tree);

        if (!isCovered) overallStatus = false;

        return isCovered ? "Covered" : "Not Covered";
      });

      setStatuses((prev) => ({
        ...prev,
        [policy.policyId]: updatedStatuses,
      }));

        setPolicyStatus(overallStatus);
    };

    calculateStatus();
  }, [answers]);

  useEffect(() => {
    autoFillAnswers();
  }, [policy]);

  return (
    <div className="p-6 rounded-lg mb-50" style={{backgroundColor: policyStatus ? "#03fc62" : "#ed5555"}}>
        <div className="flex justify-between items-center">
            <h4 className="font-bold mb-4">
                {policy.title}
            </h4>
            <button
                className="btn btn-cprimary"
                onClick={autoFillAnswers}
                disabled={loading}
                >
                {loading? "Filling" : "Auto-Fill Answers"}
            </button>
        </div>
      
      <p >{policy.description}</p>

      {policy.criteria.map((criteria, index) => (
        <Criteria
          key={index}
          criteria={criteria}
          index={index}
          onAnswerChange={onAnswerChange}
          answers={answers}
          status={statuses[policy.policyId]?.[index] || "Not Covered"}
        />
      ))}
    </div>
  );
};

export const ValidationTree = ({policies, questionIds, authRequest}) => {
  const [answers, setAnswers] = useState({});
  const [statuses, setStatuses] = useState({});

  useEffect(() => {
    questionIds.forEach((item) => {
        answers[item] = -1;
    });

  }, [questionIds]);

  return (
    <div>
      <h3 className="text-center">
        Coverage Assessment
      </h3>
      <hr/>

      {policies.map((policy) => (
        <Policy
          key={policy.policyId}
          policy={policy}
          answers={answers}
          setAnswers={setAnswers}
          statuses={statuses}
          setStatuses={setStatuses}
          authRequest={authRequest}
        />
      ))}
    </div>
  );
};