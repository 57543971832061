import { useContext, useEffect, useRef, useState } from "react";
import { Sidebar } from "../../components/sidebar/sidebar";
import "./main.index.css";
import { Avatar } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { ProgressBar } from "../../components/progress-bar/progress-bar";
import axios from "axios";
import { routePaths } from "../../App";

const response = [
  `
  <p><span class="fc-cprimary">Stop and arrest:</span> If an officer suspects a driver is under the influence, they will pull the vehicle over and conduct a series of sobriety tests. If the driver fails these tests or refuses to take them, the officer will arrest the driver for DUI.</p>
  <p><span class="fc-cprimary">Investigation and evidence collection:</span> Once a driver is arrested for DUI, the officer will investigate the scene and collect evidence such as the driver’s blood alcohol content (BAC) level, the results of sobriety tests, and the driver’s behavior and appearance.<p>
  <p><span class="fc-cprimary">Testifying in court:</span> If the case goes to trial, the officer may be called as a witness to testify about the arrest and the evidence they collected.<p>
  <p><span class="fc-cprimary">Enforcing laws and regulations:</span> Law enforcement officers are responsible for enforcing all laws and regulations, including those related to drunk driving. They will also enforce laws related to open containers, and also any other vehicle code violation that is noticed while conducting a DUI investigation.<p>
  <p><span class="fc-cprimary">Providing public safety:</span> Law enforcement officers also play an important role in promoting public safety by enforcing laws against drunk driving, which can help reduce the number of DUI-related accidents and fatalities on the roads.<p>
`,
];

export const Main = () => {
  const [convo, setConvo] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [messages, setMessages] = useState([]);
  const containerRef = useRef(null);
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   let index = 0;

  //   const intervalId = setInterval(() => {
  //     if (index < response.length) {
  //       setMessages((prevMessages) => [...prevMessages, response[index]]);
  //       index++;
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 500);

  //   return () => clearInterval(intervalId);
  // }, [response]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [convo]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e?.target);
    const prompt = formData.get("prompt");

    const updatedConvo = [
      ...convo,
      {
        content: prompt,
        role: "user",
      },
    ];
    setConvo(updatedConvo);

    e?.target?.reset();
    setLoading(true);

    const res = await fetch(
      "https://ai-chatbot-backend-ouz2.onrender.com/ask-ai",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*", // It can be used to overcome cors errors
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: updatedConvo,
        }),
      }
    );

    const ress = await res.json();
    console.log(
      ress?.chatCompletion?.[0]?.message,
      "ress?.chatCompletion?.[0]?.message",
      ress
    );
    setLoading(false);
    setConvo((prev) => [
      ...prev,
      {
        ...ress?.chatCompletion?.[0]?.message,
      },
    ]);
  };

  const handleNewChat = () => {
    setConvo([]);
  };

  return (
    <div className={`row main-div animate-left-to-right m-0`}>
      <div className="col-5 ps-4 pe-2">
        <div className="border shadow-2 p-4 mt-3 rounded user-select-none">
          {appState?.allDocsSelected ? (
            <p className="ff-os m-0 text-secondary">
              All the documents are selected.
            </p>
          ) : appState?.selectedDocs ? (
            <>
              <p className="ff-os m-0 fw-600 mb-4 fs-20 hello-greeting">
                {appState?.selectedDocs?.title}
              </p>
              <p className="ff-os m-0 fw-400 fs-16 text-secondary">
                {appState?.selectedDocs?.da}
              </p>
              <p
                className="ff-os m-0 fw-400 fs-16 text-secondary"
                dangerouslySetInnerHTML={{
                  __html: appState?.selectedDocs?.indication,
                }}
              />
            </>
          ) : (
            <p className="ff-os m-0 text-secondary">
              No documents are selected
            </p>
          )}
        </div>
      </div>

      <div className="col-7 d-flex flex-column main-content p-0 px-4">
        <div
          ref={containerRef}
          className="d-flex flex-column align-items-start justify-content-start w-100 main-div-chatbot"
          style={{
            height: "73vh",
          }}
        >
          <div className="flex-row w-100 d-flex align-items-center justify-content-between pointer user-select-none mb-2">
            <div className="d-flex align-items-center">
              <i class="bi bi-chat fs-20 fw-600 hello-greeting me-3"></i>
              <p className="ff-os m-0 fs-16 text-center hello-greeting fw-500">
                Talk with the refined documents
              </p>
            </div>
            <button
              onClick={() => {
                navigate(routePaths.root);
              }}
              type="button"
              className="btn text-secondary align-items-center d-flex"
            >
              <i class="bi bi-arrow-left fs-20"></i>
              <span className="ms-2">Previous</span>
            </button>
          </div>
          <div class="p-4 my-2 bg-cprimary rounded shadow-sm">
            <p className="ff-os fw-600 m-0 fc-cprimary fs-18">
              <i class="bi bi-info-circle fs-20 me-2"></i> Engage with AI-Driven
              Insights
            </p>
            <p className="ff-os m-0 fs-14 fc-csecondary fw-400 mt-3">
              Dive into a dynamic conversation with our AI, designed to help you
              explore your selected documents in depth. Ask questions, get
              tailored responses, and uncover key insights effortlessly. Let the
              AI guide you through the information, making your research more
              efficient and intuitive.
            </p>
          </div>

          <div className="container mt-3 p-0">
            {convo.map((con, idx) => (
              <div
                key={idx}
                className={`d-flex message mb-1 ${
                  con?.isUser ? "user" : "system"
                }`}
              >
                {con?.role === "user" ? (
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt={
                      appState?.userData?.displayName ||
                      appState?.userData?.email
                    }
                    className="fs-14 m-0"
                    src="/static/images/avatar/1.jpg"
                  />
                ) : (
                  <i class="bi bi-stars fs-24 fc-cprimary"></i>
                )}
                <div className="mb-2">
                  <p
                    className="m-0 ms-2 mt-1"
                    dangerouslySetInnerHTML={{ __html: con?.content }}
                  />
                  {/* <p >{con?.message}</p> */}
                  {con?.role !== "user" && (
                    <div className="ms-2 my-4">
                      <i class="bi bi-hand-thumbs-up fs-16 fc-csecondary pointer"></i>
                      <i class="bi bi-hand-thumbs-down fs-16 fc-csecondary ms-4 pointer"></i>
                      <i class="bi bi-three-dots-vertical fs-16 fc-csecondary ms-4 pointer"></i>
                      <i class="bi bi-google fs-16 fc-csecondary ms-4 pointer"></i>
                      <i class="bi bi-clipboard fs-16 fc-csecondary ms-4 pointer"></i>
                      <i class="bi bi-share fs-16 fc-csecondary ms-4 pointer"></i>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {loading && (
              <div className="d-flex justify-content-start">
                <i class="bi bi-stars fs-24 fc-cprimary" style={{}}></i>
                <div className="w-100 ms-3">
                  <SkeletonTheme baseColor="#9b72cb50" highlightColor="#fff">
                    <Skeleton className="w-100 p-0 m-0" />
                    <Skeleton className="w-50" />
                    <Skeleton className="w-75" />
                  </SkeletonTheme>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            height: "17vh",
          }}
          className="d-flex flex-column align-items-center justify-content-center w-100"
        >
          <form onSubmit={handleFormSubmit} className="w-100 px-5 chatbot-form">
            <div className="input-div d-flex flex-1 form-control w-100">
              <input
                required
                name="prompt"
                placeholder="Please enter a prompt here"
                className="w-100 bg-none outline-none shadow-none py-2 px-4"
              />
              <div className="d-flex align-items-center">
                <button type="submit" className="btn mx-2">
                  <i class="bi bi-send fc-cprimary fs-20"></i>
                </button>
                {/* <div className="mx-2">
                  <i class="bi bi-mic fs-24 fc-cprimary"></i>
                </div> */}
              </div>
            </div>
          </form>
          <p className="ff-os fs-12 m-0 mt-3">
            {appState?.appName} may display inaccurate info, including about
            people, so double-check its responses.
          </p>
        </div>
      </div>
    </div>
  );
};
