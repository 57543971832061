import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext, intialContextValues } from "../../context/AppContext";
import { auth } from "../../firebase/config";
import { routePaths } from "../../App";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { addProjectToDB, getAllProjects } from "../../firebase/firestoreUtils";
import { Button, Modal } from "react-bootstrap";
export const Header = () => {
  const location = useLocation();
  const { appState, setAppState } = useContext(AppContext);
  const [currPath, setCurrPath] = useState(false);
  const [projects, setProjects] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState("");

  const init = async () => {
    setAppState((prev) => ({
      ...prev,
      headerLoading: "header",
    }));
    // console.log(appState?.userData?.uid, "appState?.userData?.uid")
    const projects = await getAllProjects(appState?.userData?.uid);
    if (!projects.docs) return;
    // console.log(
    //   projects?.docs?.map((project) => ({
    //     value: "project?.uid",
    //     label: project?.data()?.projectName,
    //   }))
    // );

    let selected_project = localStorage.getItem("selectedProjectId");

    // if (selected_project) {
    //   selected_project = JSON.parse(selected_project);
    // }
    const projects_ = projects?.docs?.map((project) => ({
      id: project.id,
      ...project?.data(),
    }));
    setProjects(projects_);
    // console.log(selected_project, "selected_project");

    if (projects_?.length > 0) {
      let projectDetails = projects_?.filter(
        (proj) => proj?.id === selected_project
      )?.[0];

      if (!projectDetails) {
        //by default selected first project
        projectDetails = projects_?.[0];
        selected_project = projectDetails?.id;
      }
      // console.log(projects_, "SDs", projectDetails);
      if (projectDetails) {
        setDefaultSelected(selected_project);
        setAppState((prev) => ({
          ...prev,
          currentProject: {
            ...prev?.currentProject,
            ...projectDetails,
          },
        }));
      }
    }

    setAppState((prev) => ({
      ...prev,
      headerLoading: false,
    }));
  };
  useEffect(() => {
    if (appState?.userData && !projects) {
      init();
    }
  }, [appState]);

  useEffect(() => {
    setCurrPath(location.pathname);
  }, [location]);

  const handleBackToHome = () => {
    console.log(location);
    // quick-filter
    const path = location?.pathname;

    setAppState((curr) => ({ ...curr, ani: "left" }));
  };

  const handleLogout = () => {
    localStorage.removeItem("selectedProjectId");
    // setAppState((prev) => ({ ...prev, userData: null }));
    setAppState({...intialContextValues})
    auth.signOut();
    setTimeout(() => {
      window.location.reload()

    }, 500)
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);

    const projectName = formData.get("projectName");

    setAppState((prev) => ({
      ...prev,
      headerLoading: "modal",
    }));
    const res = await addProjectToDB(
      {
        projectName,
        history: [],
        categories: [],
        searchQuery: "",
      },
      appState?.userData?.uid
    );
    if (res?.id) {
      localStorage.setItem("selectedProjectId", res?.id);
    }

    init();

    setAppState((prev) => ({
      ...prev,
      headerLoading: false,
    }));
    setAppState((prev) => ({
      ...prev,
      showCreateProjectModal: false,
    }));
  };

  const handleProjectSelection = (e) => {
    const id = e?.value;
    localStorage.setItem("selectedProjectId", id);
    const projectDetails = projects?.filter((proj) => proj?.id === id)?.[0];
    console.log(e, projects, "SDs", projectDetails);
    if (projectDetails) {
      setAppState((prev) => ({
        ...prev,
        currentProject: {
          ...prev?.currentProject,
          ...projectDetails,
        },
      }));
    }
  };

  return [routePaths.root, routePaths.chatWithAI]?.includes(currPath) ? (
    <div
      style={{
        height: "8vh",
      }}
      className="d-flex align-items-center justify-content-between px-4"
    >
      <Modal
        show={appState?.showCreateProjectModal}
        onHide={() => {
          setAppState((prev) => ({
            ...prev,
            showCreateProjectModal: false,
          }));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-16">Create a new Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateProject}>
            <div className="mb-4">
              <label className="form-label ff-os fw-600 fc-csecondary">
                Project name
              </label>
              <input
                required
                type="text"
                name="projectName"
                className="form-control shadow-none border-cprimary"
              />
            </div>

            <button
              // disabled={googleLoginLoading || appState?.headerLoading}
              type="submit"
              className="btn w-100 btn-outline-primary fc-cprimary border-cprimary btn-cprimary shadow-none ff-os fw-500"
            >
              {appState?.headerLoading === "modal" ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm ff-os me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only ff-os">Creating...</span>
                </>
              ) : (
                "Create project"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <div className="d-flex align-items-center">
        <Link onClick={handleBackToHome} to={"/"}>
          <p className="ff-os fc-cprimary m-0 fs-20 fw-600">{appState?.appName}</p>
        </Link>
       
      </div>

      <div className="d-flex align-items-center px-2">
      {appState?.userData && (
          <p className="ff-os fs-16 m-0 me-3">
            Welcome,{" "}
            <span className="fw-600 fc-cprimary">
              {appState?.userData?.displayName || appState?.userData?.email}
            </span>
          </p>
        )}
        {/* {appState?.headerLoading === "header" ? (
          <div className="header-projects-dropdown">
            <div
              class="spinner-border fc-cprimary"
              style={{
                width: "1.6rem",
                height: "1.6rem",
              }}
              role="status"
            ></div>
          </div>
        ) : (
          <Dropdown
            arrowClassName="d-none"
            arrowClosed={<i class="bi bi-escape ms-2 fs-18"></i>}
            className="border-none"
            controlClassName="border-cprimary header-projects-dropdown"
            options={projects?.map((item) => ({
              value: item.id,
              label: item?.projectName,
            }))}
            onChange={handleProjectSelection}
            // value={}
            value={defaultSelected}
            placeholder="Projects"
          />
        )} */}

        {/* <button
          disabled={appState?.headerLoading === "header"}
          onClick={() => {
            setAppState((prev) => ({
              ...prev,
              showCreateProjectModal: true,
            }));
          }}
          className="btn p-0 px-2 mx-2"
          type="button"
        >
          <i class="bi bi-plus-circle-dotted fs-24 fc-cprimary"></i>
        </button> */}
        {appState?.userData && (
          <Link
            onClick={handleLogout}
            className="text-danger fs-16 fw-600 d-flex"
            to={"/logout"}
          >
            {/* <p className="ff-os m-0 fw-600">Logout</p> */}
            <i class="bi bi-escape ms-2 fs-18"></i>
          </Link>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
