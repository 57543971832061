import { useState } from "react";
import { Link } from "react-router-dom";
import { routePaths } from "../App";

export const PatientDetails = () => {
  const [showEvaluate, setShowEvaluate] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const patientDetails = [
    {
      title: "Name",
      value: "Jane Doe",
    },
    {
      title: "Date of Birth",
      value: "05/15/1980",
    },
    {
      title: "Insurance ID",
      value: "MED123456789",
    },
  ];
  const requestingProvider = [
    {
      title: "Name",
      value: "Dr. John Smith",
    },
    {
      title: "MD NPI",
      value: "1234567890",
    },
    {
      title: "Specialty",
      value: "Allergy and Immunology",
    },
  ];
  return (
    <div className="container h-100">
      <div className="w-100 my-2">
        <Link to={routePaths.root}>
          <i class="bi bi-arrow-left-circle fs-24 fw-500 fc-cprimary pointer"></i>
        </Link>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Patient Information:</p>
            <div className="border-cprimary rounded my-2 px-2 py-1">
              {patientDetails?.map((det) => (
                <div className="p-2 d-flex">
                  <p className="m-0 fw-600 me-2">{det?.title}: </p>
                  <p className="m-0 fw-400">{det?.value}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Requesting Provider:</p>
            <div className="border-cprimary rounded my-2 px-2 py-1">
              {requestingProvider?.map((det) => (
                <div className="p-2 d-flex">
                  <p className="m-0 fw-600 me-2">{det?.title}: </p>
                  <p className="m-0 fw-400">{det?.value}</p>
                </div>
              ))}

              <div className="p-2 d-flex flex-column">
                <p className="m-0 fw-600 me-2">Diagnosis: </p>
                <div className="d-flex flex-column ms-2 mt-2">
                  <p className="m-0 fw-700">Primary</p>
                  <p className="m-0 fw-400 fs-14">
                    J30.1 Allergic rhinitis due to pollen
                  </p>
                </div>

                <div className="d-flex flex-column ms-2 mt-2">
                  <p className="m-0 fw-700">Secondary</p>
                  <p className="m-0 fw-400 fs-14">
                    J45.909 Unspecified asthma, uncomplicated
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Clinical Information :</p>
            <div className="border-cprimary rounded my-2 px-2 py-1">
              {[
                "Patient has a confirmed diagnosis of allergic rhinitis and allergic asthma based on clinical history and specific IgE antibody testing.",
                "Skin prick tests show positive reactions to grass pollen, tree pollen, and dust mites.",
                "Patient has failed to achieve adequate symptom control with the following conservative treatments:",
                "Patient's asthma is currently stable and well-controlled on low-dose inhaled corticosteroids.",
                "Allergen avoidance is impractical due to widespread environmental exposure.",
              ]?.map((det, index) => (
                <div className="p-2 d-flex flex-column">
                  <p className="m-0 fw-400 me-2">
                    {index + 1}. {det}
                  </p>
                  {index == 2 && (
                    <div className="ms-4 mt-2">
                      <p className="m-0 my-1 fs-14 fw-500">
                        * Oral antihistamines (loratadine, cetirizine)
                      </p>
                      <p className="m-0 fs-14 my-1  fw-500">
                        * Intranasal corticosteroids (fluticasone)
                      </p>
                      <p className="m-0 fs-14 fw-500">
                        * Leukotriene receptor antagonist (montelukast)
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Proposed Treatment Plan:</p>
            <div className="border-cprimary rounded my-2 px-2 py-1">
              {[
                "Subcutaneous immunotherapy (SCIT) for grass pollen, tree pollen, and dust mite allergens",
                "Build-up phase: Weekly injections for 28 weeks",
                "Maintenance phase: Monthly injections for 3-5 years",
                "Injections to be administered in a medical setting equipped to manage potential anaphylaxis",
                "30-minute observation period after each injection",
              ]?.map((det, index) => (
                <div className="p-2 d-flex flex-column">
                  <p className="m-0 fw-400 me-2">
                    {index + 1}. {det}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Supporting Documentation:</p>
            <div className="border-cprimary rounded my-2 px-2 py-1">
              {[
                "Allergy test results",
                "Pulmonary function tests",
                "Medication history",
                "Treatment plan with specified starting dose, target maintenance dose, and schedule",
              ]?.map((det, index) => (
                <div className="p-2 d-flex flex-column">
                  <p className="m-0 fw-400 me-2">
                    {index + 1}. {det}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="my-3">
            <p className="m-0 fw-700 fs-20">Supporting Documentation:</p>
            <div className="border-cprimary rounded my-2 px-2 py-3">
              <p className="m-0 fw-400 me-2">
                I certify that the information provided is true and accurate to
                the best of my knowledge. The requested treatment is medically
                necessary for this patient's condition.
              </p>
            </div>
          </div>

          <div className="d-flex align-items-end justify-content-end mt-3 mb-2">
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setShowLoader(true);
                setTimeout(() => {
                  setShowLoader(false);
                  setShowEvaluate(true);
                }, 4000);
              }}
              className={`btn btn-cprimary fw-400 fs-14 ml-auto active`}
            >
              Evaluate
            </button>
          </div>
        </div>
        {showLoader && (
          <div className="col-6 p-4">
            <div class="spinner-grow bg-cprimary" role="status">
              {/* <span class="sr-only">Loading...</span> */}
            </div>
            <p className="fs-16 fw-500 fc-cprimary m-0">
              Please wait while we evaluating...
            </p>
          </div>
        )}
        {showEvaluate && (
          <div className="col-6 p-3">
            <p className="fw-500 fs-20 m-0 mb-4">
              Based on the allergy immunotherapy policy document provided,
              here's a concise list of yes/no/NA questions to determine medical
              coverage: Indications (AND logic):
            </p>
            {[
              {
                question:
                  "Does the patient have a diagnosis of allergic rhinitis, allergic conjunctivitis, allergic asthma, or stinging insect hypersensitivity?",
                answer: "Yes",
              },
              {
                question:
                  "  Has the patient's allergy been confirmed through appropriate diagnostic procedures, including clinical listory and specific IgE antibody testing?",
                answer: "Yes",
              },
              {
                question:
                  "Have conservative therapies (including avoidance and pharmacotherapy) failed to adequately control the patient's symptoms?",
                answer: "Yes",
              },
              {
                question:
                  "Is the allergen causing symptoms unavoidable or impractical to avoid in the patient's environment?",
                answer: "Yes",
              },
              {
                question:
                  "Is the immunotherapy being prescribed and administered by a physician trained and experienced in allergy immunotherapy?",
                answer: "Yes",
              },
              {
                question:
                  "Will the immunotherapy be administered in a setting equipped to recognize and manage potential adverse reactions, including anaphylaxis?",
                answer: "Yes",
              },
              {
                question:
                  "Has a customized treatment plan with specified starting dose, target maintenance dose, and schedule been developed for the patient?",
                answer: "Yes",
              },
              {
                question:
                  "Is the patient being treated for a non-covered condition such as food allergy, intrinsic asthma, or non-allergic vasomotor rhinitis?",
                answer: "No",
              },
              {
                question:
                  "Is the proposed immunotherapy using non-standard methods such as oral, sublingual, epicutaneous, or intralymphatic administration?",
                answer: "No",
              },
              {
                question:
                  " Does the treatment involve therapies considered experimental or investigational, such as allergoids, bacterial vaccines, or urine auto-injection?",
                answer: "No",
              },
              {
                question:
                  " Is the patient mentally or physically unable to communicate clearly with the allergist?",
                answer: "No",
              },
              {
                question:
                  "Does the patient have a history of non-compliance with medical treatments?",
                answer: "No",
              },
              {
                question:
                  "For patients with asthma, is the condition poorly controlled or severe?",
                answer: "No",
              },
              {
                question:
                  " Has the immunotherapy treatment extended beyond 24 months without evidence of clinical benefit (decreased symptoms, increased allergen tolerance, and reduced medication usage)?",
                answer: "No",
              },
            ].map((list, index) => (
              <div className="mb-5">
                <p className="m-0 mb-1 fw-400 fs-14 fc-csecondary">
                  {index + 1}. {list?.question}
                </p>
                <p className="text-end m-0 fw-600 fs-20 fc-cprimary">
                  {list?.answer}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
