import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { AppContext } from "../context/AppContext";
import ConnectedCustomRefinementList from "./CustomRefinementList";
import { useState } from "react";

const CustomSearchBox = ({ currentRefinement, refine }) => {
  const { appState, setAppState } = useContext(AppContext);
  const [hasContent, setHasContent] = useState(false);

  const handleChange = (event) => {
    console.log(event.target.value);

    if (typeof event.target.value === typeof "hello") {
      // localStorage.setItem("searchQuery", searchQuery);
      refine(event.target.value);

      setAppState((prev) => ({
        ...prev,
        currentProject: {
          ...prev?.currentProject,
          searchQuery: event.target.value,
        },
      }));
    }
  };

  return (
    // <div className="custom-searchbox col-10 mx-auto">
    <>
      <textarea
        className="ais-SearchBox-input p-2 px-3 mb-1"
        value={currentRefinement}
        onChange={handleChange}
        placeholder="Type your search query here..."
        rows={1} // Adjust the number of rows as needed
        style={{ width: "100%" }} // Customize style as needed
      />
      <div className={`${hasContent ? "border-cprimary" : ""} rounded p-1`}>
        <ConnectedCustomRefinementList setHasContent={setHasContent} attribute="source" />
        <ConnectedCustomRefinementList setHasContent={setHasContent} attribute="type" />
      </div>
    </>
    // </div>
  );
};

export default connectSearchBox(CustomSearchBox);
