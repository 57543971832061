import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDfRI8wocitN9EhGvjolxZe8TKdL9gtirc",
  authDomain: "doc-chatbot-demo.firebaseapp.com",
  projectId: "doc-chatbot-demo",
  storageBucket: "doc-chatbot-demo.appspot.com",
  messagingSenderId: "345021726026",
  appId: "1:345021726026:web:5e801308ab2f80607195b3",
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ timestampsInSnapshots: true });

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export { firebase };
