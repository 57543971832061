import { Pagination, SearchBox } from "react-instantsearch-dom";
import MoviesHits from "../../components/moviesHits";
import "instantsearch.css/themes/satellite.css";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "./filter.index.css";
import { ProgressBar } from "../../components/progress-bar/progress-bar";
import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../../App";
import { updateProject } from "../../firebase/firestoreUtils";
import CustomSearchBar from "../../components/CustomSearchBar";
import { Link } from "react-router-dom";

const options = {
  year: "numeric",
  month: "long", // 'long' for full month name, 'short' for abbreviated month, 'numeric' for number
  day: "numeric",
};

const stickyStyle = {
  position: "sticky",
  top: "0",
};

export const Filter = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { appState, setAppState } = useContext(AppContext);
  const [currpath, setCurrpath] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCurrpath(location.pathname);
  }, [location]);

  useEffect(() => {
    const searchQueryLocal = localStorage.getItem("searchQuery");
    if (location?.pathname === routePaths.root) {
      setSearchQuery(searchQueryLocal);
    }
  }, [location?.pathname]);

  useEffect(() => {
    // if (appState?.currentProject?.searchQuery) {
    setSearchQuery(appState?.currentProject?.searchQuery);
    // }
  }, [appState?.currentProject]);

  useEffect(() => {
    if (searchQuery) {
      // localStorage.setItem("searchQuery", searchQuery);

      setAppState((prev) => ({
        ...prev,
        currentProject: {
          ...prev?.currentProject,
          searchQuery,
        },
      }));
    }
  }, [searchQuery]);

  return (
    <div className={`animate-right-to-left row px-4`}>
      {/* <div
          className="w-100 p-2 shadow-2 bg-gray"
          style={{
            top: "85%",
            minHeight: "15vh",
          }}
        >
          <div className="mt-2 w-75 px-4">
            <h6 className="mt-4 mb-2">
              Refine your document selection using the filters below.
            </h6>
            <p className="ff-os fw-400 m-0 fs-14">
              Use the filters and search bar below to refine your document
              selection. Adjust the categories, keywords, and other criteria to
              narrow down the list to the most relevant documents. This will help
              you find exactly what you need from our extensive collection.
            </p>
          </div>
        </div> */}
      {/* <ProgressBar /> */}

      <div className="d-flex flex-column col-6">
        {appState?.headerLoading === "header" ? (
          <></>
        ) : (
          <div className="flex-row w-100 d-flex align-items-center justify-content-between pointer user-select-none mb-2">
            <div className="d-flex align-items-center">
              {/* <i class="bi bi-filter-circle fs-20 fw-600 fc-cprimary me-3"></i>
              <p className="ff-os m-0 fs-16 text-center hello-greeting fw-600">
                Refine your document choices
              </p> */}
            </div>
            {/* <button
              onClick={async () => {
                // here save the progress

                // let refine_list_local = localStorage.getItem("refine_list");
                // if (refine_list_local) {
                //   refine_list_local = JSON.parse(refine_list_local);
                // }
                // const refine_list = refine_list_local;

                // let project = localStorage.getItem("selected_project");
                // if (project) {
                //   project = JSON.parse(project);
                // }

                console.log("APP", appState?.currentProject);
                if (appState?.currentProject?.id) {
                  // update the project details
                  setLoading(true);
                  await updateProject(
                    appState?.userData?.uid,
                    appState?.currentProject?.id,
                    appState?.currentProject
                  );
                  navigate(routePaths.chatWithAI);
                  setLoading(false);
                } else {
                  if (
                    window.confirm(
                      "To save your selections, please create a project. This way, you can easily revisit and manage your choices anytime!"
                    )
                  ) {
                    //show modal
                    setAppState((prev) => ({
                      ...prev,
                      showCreateProjectModal: true,
                    }));
                  } else {
                    navigate(routePaths.chatWithAI);
                  }
                }
              }}
              type="button"
              // disabled={appState?.headerLoading}
              className="btn text-secondary align-items-center d-flex"
            >
              {loading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm ff-os me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only ff-os">Saving</span>
                </>
              ) : (
                <>
                  <span className="me-2">Save & Next</span>
                  <i class="bi bi-arrow-right fs-20"></i>
                </>
              )}
            </button> */}
          </div>
        )}
        {/* <h1 className="hello-greeting">Hello,</h1>
            <h3 className="greeting-subheading">How can I help you today?</h3> */}

        {appState?.headerLoading === "header" ? (
          <div className="mt-4">
            <span
              class="spinner-border spinner-border-sm ff-os me-2"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only ff-os fs-14 fc-csecondary">
              Hang tight! We're getting everything ready for you. This will just
              take a moment.
            </span>
          </div>
        ) : (
          <>
            <div className="mt-3 bg-white" style={stickyStyle}>
              {/* <SearchBox
                className="col-9 mx-auto"
                  onReset={() => {
                    setAppState((prev) => ({
                      ...prev,
                      currentProject: {
                        ...prev?.currentProject,
                        searchQuery: "",
                      },
                    }));
                    // localStorage.setItem("searchQuery", "");
                  }}
                  defaultRefinement={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e?.target?.value);
                  }}
                /> */}
              <CustomSearchBar />
            </div>
            {/* <div class="px-4 py-3 my-2 bg-cprimary d-flex align-items-start rounded">
            <p className="ff-os fw-600 m-0 fc-cprimary fs-18">
                Refine your
                  document selection using the filters below.
                </p>
                <p className="ff-os m-0 fs-14 fc-csecondary fw-400 my-3">
                  Use the filters and search bar below to refine your document
                  selection. Adjust the categories, keywords, and other criteria
                  to narrow down the list to the most relevant documents. This
                  will help you find exactly what you need from our extensive
                  collection.
                </p> */}
            {/* <i class="bi bi-info-circle fs-20 me-3"></i>
              <p className="ff-os m-0 fs-14 fw-600 fc-cprimary">
                Use the search bar and filters below to refine your document
                selection, then click 'Save & Next' to proceed.
              </p>
            </div> */}

            <MoviesHits />
          </>
        )}
      </div>

      {/* <div style={}> */}
      {appState?.resDetails && (
        <div
          className="d-flex flex-column col-6 mt-4 px-4 py-4 ms-auto border-cprimary rounded shadow-1 main-div-chatbot"
          style={{
            overflowY: "auto",
            maxHeight: "88vh",
          }}
        >
          {/* <div className="d-flex align-items-center justify-content-end w-100">
            <Link to={routePaths.patientDetails}>
              <i class="bi bi-arrow-right-circle fs-24 fw-500 fc-cprimary pointer"></i>
            </Link>
          </div> */}
          <p className="m-0 fw-500 fs-20 mb-2">{appState?.resDetails?.title}</p>

          <div className="mt-1 mb-2">
            {/* <MovieHighlight hit={hit} attribute="vote_average" />{" "} */}
            <span className="border px-2 fs-16 fw-400 source-box">
              {appState?.resDetails?.source}
            </span>
            <a
              href={appState?.resDetails?.url}
              target="_blank"
              className="border px-2 fs-16 fw-400 ms-2 source-box"
            >
              Source link
            </a>
          </div>
          {appState?.resDetails?.date && (
            <p className="m-0 fw-500 fs-18 mb-3">
              {" "}
              {new Date(appState?.resDetails?.date)?.toLocaleDateString(
                "en-US",
                options
              )}
            </p>
          )}
          {appState?.resDetails?.lcd_id && (
            <p className="m-0 fw-500 fs-18 mb-3">
              LCD: {appState?.resDetails?.lcd_id}
            </p>
          )}
          <p
            className="m-0 fw-400 fs-16"
            dangerouslySetInnerHTML={{
              __html: appState?.resDetails?.indication,
            }}
          />
        </div>
      )}

      {/* </div> */}
    </div>
  );
};
