import React, { useEffect } from "react";
import { connectHits } from "react-instantsearch-core";
import styled from "styled-components";
import { MovieHit } from "../movieHit";
import { Pagination } from "react-instantsearch-dom";

const HitsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

function MoviesHits({ hits, isLoading, hasMore }) {
  useEffect(() => {
    console.log(hits);
    const appp = hits?.map((d) => ({
      label: d?.title,
      value: d?.objectID,
    }));
    console.log(appp);
  }, [hits]);

  if (!hits || hits?.length === 0)
    return (
      <div className="mt-4">
        <span
          class="spinner-border spinner-border-sm ff-os me-2"
          role="status"
          aria-hidden="true"
        ></span>
        <span class="sr-only ff-os ms-2">Please wait...</span>
      </div>
    );
  return (
    <div className="">
      {/* {isLoading ? "Helllo" : "ladadads"} */}
      {hits
        // .filter((hit) => !hit?.indication)
        ?.map((hit) => (
          <MovieHit key={hit.ObjectID} hit={hit} />
        ))}
      {hits.length > 0 && (
        <div className="d-flex w-100 flex-1 mb-5 align-items-center justify-content-center">
          <Pagination />
        </div>
      )}
    </div>
  );
}

export default connectHits(MoviesHits);
